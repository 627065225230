import React from 'react';
import Heading from '@vfuk/core-heading';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import MainLayout from '../layouts/MainLayout';
import options from '../helpers/ContentFulOptions';
import Block from '@vfuk/core-block';
import { StyledBody, transparent } from './NotFound.styles';
import { NotFoundProps } from './NotFound.types';

const NotFound = ({ heading, bodyObject }: NotFoundProps) => {
  const fallbackHeading = '404 - Page not found';
  const fallbackBodyText = `Sorry, the page you have requested does not exist`;

  return (
    <MainLayout>
      <Block className="section" css={transparent}>
        <div className="spring">
          <StyledBody>
            <Heading noMargin={false} level={1}>
              {heading || fallbackHeading}
            </Heading>
            {(bodyObject.content && documentToReactComponents(bodyObject, options)) || fallbackBodyText}
          </StyledBody>
        </div>
      </Block>
    </MainLayout>
  );
};

export default NotFound;